import React from 'react';
import { Box, Stack, useTheme, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FacebookLogo, InstagramLogo, LinkedinLogo, TwitterLogo } from 'phosphor-react';

const menuItems = ['Home', 'About us', 'Press', 'Careers', 'Help'];
const menuItems2 = [
  'The Paper Gown Stories for and about patients',
  'The Script Insights for doctors',
  'Community Standards',
  'Data and privacy',
  'Verified reviews',
];
const medicalSpecialties = [
  'Primary Care Doctor',
  'Urgent Care',
  'Dermatologist',
  'OB-GYN',
  'Dentist',
  'Psychiatrist',
  'Ear, Nose & Throat Doctor',
  'Podiatrist',
  'Urologist',
  'Gastroenterologist',
  'Cardiologist',
  'Neurologist',
  'Orthopedic Surgeon',
  'Ophthalmologist',
  'Pediatrician',
  'Optometrist',
  'Eye Doctor',
  'Therapist Counselor',
  'Physical Therapist',
  'Psychologist',
  'View all',
];

const askDocItems = [
  'List your practice on AskDoc',
  'Learn about AskDoc for Health Systems',
  'Become an EHR partner with AskDoc',
  'Access AskDoc for Developers',
  'Learn about AskDoc Enterprise Solutions',
];
const medicalMarketingSpecialties = [
  'Dental Marketing',
  'OB-GYN Marketing',
  'Dermatology Marketing',
  'Primary Care Marketing',
  'Psychiatry Marketing',
];

function Footer() {
  const theme = useTheme();
  return (
    <Stack>
      <Box
        px={{ md: 12, xs: 6 }}
        pt={5}
        sx={{ maxWidth: theme.breakpoints.values.xl, bgcolor: theme.palette.grey[700] }}
      >
        <Stack gap={4} direction="row" justifyContent="space-between" flexWrap="wrap">
          <Stack gap={1}>
            <Typography mb={1.2} variant="h5" color="grey.100">
              Ask doc
            </Typography>
            {menuItems.map((ele) => (
              <Link key={ele} className="default-link" to="#">
                {' '}
                <Typography variant="body1" fontWeight={400} color="grey.300">
                  {ele}
                </Typography>
              </Link>
            ))}

            <Typography mt={4} mb={1.2} variant="h5" color="grey.100">
              Contact
            </Typography>
            <Link className="default-link" to="#">
              <Typography variant="body1" fontWeight={400} color="grey.300">
                service@askdoc.com
              </Typography>
            </Link>
            <Link className="default-link" to="#">
              <Typography variant="body2" color="grey.300">
                888-555-8568{' '}
              </Typography>
            </Link>
          </Stack>
          <Stack gap={1}>
            <Typography mb={1.2} variant="h5" color="grey.100">
              Discover
            </Typography>
            {menuItems2.map((ele) => (
              <Link key={ele} className="default-link" to="#">
                {' '}
                <Typography variant="body1" fontWeight={400} color="grey.300">
                  {ele}
                </Typography>
              </Link>
            ))}
          </Stack>
          <Stack gap={1}>
            <Typography mb={1.2} variant="h5" color="grey.100">
              Top Specialties
            </Typography>
            {medicalSpecialties.map((ele) => (
              <Link key={ele} className="default-link" to="#">
                {' '}
                <Typography variant="body1" fontWeight={400} color="grey.300">
                  {ele}
                </Typography>
              </Link>
            ))}
          </Stack>
          <Stack gap={1}>
            <Typography mb={1.2} variant="h5" color="grey.100">
              Are you a top doctor or health service?
            </Typography>
            {askDocItems.map((ele) => (
              <Link key={ele} className="default-link" to="#">
                <Typography variant="body1" fontWeight={400} color="grey.300">
                  {ele}
                </Typography>
              </Link>
            ))}

            <Typography mt={4} mb={1.2} variant="h5" color="grey.100">
              Marketing for your practice
            </Typography>
            {medicalMarketingSpecialties.map((ele) => (
              <Link key={ele} className="default-link" to="#">
                <Typography variant="body1" fontWeight={400} color="grey.300">
                  {ele}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>
        <Typography variant="subtitle2" fontWeight="400" pt={4} pb={2} color="grey.300">
          The content provided here and elsewhere on the Askdoc site or mobile app is provided for
          general informational purposes only. It is not intended as, and Askdoc does not provide,
          medical advice, diagnosis or treatment. Always contact your healthcare provider directly
          with any questions you may have regarding your health or specific medical advice.
        </Typography>
      </Box>
      <Box
        px={{ md: 12, xs: 6 }}
        py={4}
        sx={{
          width: 1,
          maxWidth: theme.breakpoints.values.xl,
          bgcolor: theme.palette.common.black,
        }}
      >
        <Stack width={1} direction="row" gap={2} flexWrap="wrap">
          <Typography variant="body1" color="white">
            © 2023 Askdoc, Inc.
          </Typography>
          <Link className="default-link" to="#">
            <Typography variant="body1" fontWeight={400} color="grey.300">
              Terms
            </Typography>
          </Link>
          <Link className="default-link" to="#">
            <Typography variant="body1" fontWeight={400} color="grey.300">
              Privacy
            </Typography>
          </Link>
          <Link className="default-link" to="#">
            <Typography variant="body1" fontWeight={400} color="grey.300">
              Your privacy choices
            </Typography>
          </Link>
          <Box marginLeft="auto">
            <Stack direction="row">
              <IconButton>
                <FacebookLogo fill="white" />
              </IconButton>
              <IconButton>
                <InstagramLogo fill="white" />
              </IconButton>
              <IconButton>
                <TwitterLogo fill="white" />
              </IconButton>
              <IconButton>
                <LinkedinLogo fill="white" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Footer;
