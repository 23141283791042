import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { MagnifyingGlass, Gear, Bell, SignOut, List as ListIcon } from 'phosphor-react';
import Logo from '../../components/logo/Logo';
import { PATH_AUTH } from '../../routes/paths';

const defaultNavigationLinks = [
  { path: '#', title: 'Home' },
  { path: '#', title: 'Find Doctors' },
  { path: '#', title: 'Find Medicals' },
  { path: '#', title: 'Consult Prime' },
  { path: '#', title: 'Help' },
];

function Navbar({ navigationLinks = defaultNavigationLinks, staticNavbar = false }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        width: 1,
        position: staticNavbar ? 'static' : 'fixed', // Determine positioning based on the prop
        boxShadow: scrolling ? theme.shadows[5] : 'none',
        backgroundColor: scrolling ? 'white' : 'transparent',
        zIndex: 50,
        transition: 'background-color 0.3s, box-shadow 0.3s',
      }}
      px={4}
    >
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Stack justifyContent="space-between" height={1}>
          <List sx={{ width: '80vw', maxWidth: '500px', paddingTop: 5 }}>
            {navigationLinks.map((link, index) => (
              <ListItem key={link.title} disablePadding>
                <ListItemButton>
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Stack direction="column" pb={4} px={2} gap={2} width={1}>
            <Link to={PATH_AUTH.login}>
              <Button flex={1} variant="outlined" color="primary">
                Log in
              </Button>
            </Link>
            <Link to={PATH_AUTH.signup}>
              <Button flex={1} variant="contained" color="primary">
                Sign up
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Drawer>
      <Stack
        marginX="auto"
        sx={{ height: '72px', maxWidth: theme.breakpoints.values.xl }}
        direction="row"
        alignItems="center"
      >
        <Box sx={{ marginRight: '110px' }}>
          <Logo />
        </Box>
        <Stack direction="row" sx={{ display: { xs: 'none', md: 'flex' } }} spacing={0.5}>
          {navigationLinks.map((link, index) => (
            <NavItem key={index} path={link.path} title={link.title} />
          ))}
        </Stack>
        <Stack
          direction="row"
          sx={{ display: { xs: 'none', md: 'flex' } }}
          marginLeft="auto"
          alignItems="center"
        >
          <Link to={PATH_AUTH.login}>
            <Button flex={1} variant="outlined" color="primary">
              Log in
            </Button>
          </Link>
          <Link to={PATH_AUTH.signup}>
            <Button flex={1} variant="contained" color="primary">
              Sign up
            </Button>
          </Link>
        </Stack>
        <Button sx={{ display: { md: 'none' }, marginLeft: 'auto' }} onClick={() => setOpen(true)}>
          <ListIcon size={36} />
        </Button>
      </Stack>
    </Box>
  );
}

Navbar.propTypes = {
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  staticNavbar: PropTypes.bool, // Prop to control static positioning
};

function NavItem({ path, title }) {
  return (
    <NavLink className="default-link" to={path}>
      <Typography
        variant="body1"
        color="grey.800"
        py={2}
        px={2.5}
        sx={{ ':hover': { color: 'text' }, htextDecoration: 'none', fontWeight: 600 }}
      >
        {title}
      </Typography>
    </NavLink>
  );
}

export default Navbar;

// Add PropTypes for prop validation
NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
