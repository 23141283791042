import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/auth/Login')));
export const SignupPage = Loadable(lazy(() => import('../pages/auth/SignUp')));
export const ForgotPasswordPage = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
export const CheckEmailPage = Loadable(lazy(() => import('../pages/auth/CheckEmail')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPassword')));
export const PasswordResetPage = Loadable(lazy(() => import('../pages/auth/PasswordReset')));
export const EmailVerifiedPage = Loadable(lazy(() => import('../pages/auth/EmailVerified')));
export const VerifyEmailPage = Loadable(lazy(() => import('../pages/auth/VerifyEmail')));
export const SearchDoctorPage = Loadable(lazy(() => import('../pages/search/search-doc-page')));
export const ChatPage = Loadable(lazy(() => import('../pages/chat')));
export const HomePage = Loadable(lazy(() => import('../pages/patient/HomePage')));
export const AppointmentsPage = Loadable(lazy(() => import('../pages/patient/Appointments')));
export const DoctorDetails = Loadable(lazy(() => import('../pages/doctor/doctor-details')));
export const BookAppointment = Loadable(lazy(() => import('../pages/book-appointment')));
export const BookAppointmentConfirm = Loadable(lazy(() => import('../pages/book-appointment/confirm-booking')));
export const PatientInfoPage = Loadable(lazy(() => import('../pages/book-appointment/patient-info')));
export const AppointmentFeedbackSection = Loadable(lazy(() => import('../pages/book-appointment/appointment-feedback')));
export const UpcomingAppointment = Loadable(lazy(() => import('../pages/patient/upcoming-appointment')));
export const PastAppointment = Loadable(lazy(() => import('../pages/patient/past-appointment')));
export const ProfileSection = Loadable(lazy(() => import('../pages/user-menu/profile')));
export const LoginAndSecurityPage = Loadable(lazy(() => import('../pages/user-menu/login-and-security')));
export const SubscribtionCenter = Loadable(lazy(() => import('../pages/user-menu/subscription-center')));
export const PermissionPage = Loadable(lazy(() => import('../pages/user-menu/permission-section')));
export const PrivacySettingsPage = Loadable(lazy(() => import('../pages/user-menu/privacy-page')));
export const VideoPage = Loadable(lazy(() => import('../pages/chat/Video')));
export const DoctorsListPage = Loadable(lazy(() => import('../pages/admin/doctors')));
export const DoctorProfile = Loadable(lazy(() => import('../pages/admin/doctor-slug')));
export const JoinAsDoctorPage = Loadable(lazy(() => import('../pages/about/join-as-doctor-page')));
export const DoctorSignUpPage = Loadable(lazy(() => import('../pages/onboarding/doctor-sign-up')));
export const OnBoardingInfoPage = Loadable(lazy(() => import('../pages/onboarding/onboardin-flow')));
export const DoctorSetupFormPage = Loadable(lazy(() => import('../pages/onboarding/setup-form')));
export const ThankYouPage = Loadable(lazy(() => import('../sections/onboarding/thank-you-page')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
