import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTheme, Box, Grid, Stack, Button, Typography } from '@mui/material';
import { PATH_USERMENU } from '../routes/paths';
import Navbar from './patient/navbar';
import Footer from './patient/footer';

export default function UserMenuLayout() {
  const menuItems = [
    { label: 'Profile', path: PATH_USERMENU.profile },
    { label: 'Login and security', path: PATH_USERMENU.password },
    { label: 'Notifications', path: PATH_USERMENU.subscriptionCenter },
    { label: 'Permissions', path: PATH_USERMENU.permissionManagment },
    { label: 'Privacy', path: PATH_USERMENU.privacy },
  ];
  const theme = useTheme();
  const location = useLocation();
  // eslint-disable-next-line eqeqeq
  console.log(location.pathname === menuItems[0].path);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.neutral,
        maxWidth: theme.breakpoints.values.xl,
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Navbar />
      <Box
        px={{ md: 8, xs: 2 }}
        py={{ md: 12, xs: 6 }}
        borderTop={`2px solid ${theme.palette.grey[300]}`}
        bgcolor={theme.palette.background.paper}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={4} display={{ xs: 'none', md: 'block' }}>
            <Stack gap={2} pr={{ md: 12, xs: 2 }}>
              {menuItems.map((item) => {
                const buttonVarient = item.path === location.pathname ? 'contained' : 'text';
                console.log(buttonVarient);
                return (
                  <Box
                    key={item.label}
                    sx={{ borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}
                  >
                    <Button
                      fullWidth
                      component={Link}
                      to={item.path}
                      variant={buttonVarient}
                      sx={{
                        px: 4,
                        textTransform: 'none',
                        // eslint-disable-next-line eqeqeq
                      }}
                    >
                      <Typography variant="body1" width={1} textAlign="left">
                        {item.label}
                      </Typography>
                    </Button>
                  </Box>
                );
              })}
            </Stack>
          </Grid>
          <Grid item md={8} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}
