import React from 'react';
import Navbar from '../../sections/landing-page/Navbar';
import HeroSection from '../../sections/landing-page/Hero-section';
import SpecialistSection from '../../sections/landing-page/SpecialistSection';
import FeaturesSection from '../../sections/landing-page/FeaturesSection';
import CTASection from '../../sections/landing-page/cta-section';
import CTASecondSection from '../../sections/landing-page/cta-section-2';
import Footer from '../../layouts/patient/footer';

function LandingPage() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <SpecialistSection/>
      <FeaturesSection/>
      <CTASection/>
      <CTASecondSection/>
      <Footer/>
    </>
  );
}

export default LandingPage;
