import { Avatar, Divider, Box, Stack, styled, useTheme, Typography } from '@mui/material';
import React from 'react';
import { ShieldPlus, Star, ThumbsUp } from 'phosphor-react';
import Navbar from '../../landing-page/Navbar';

const DOCTOR_IMAGE =
  'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg';

export const MyDivider = styled(Divider)(({ thiccness, orientation }) => ({
  ...(thiccness !== undefined &&
    (orientation === 'vertical'
      ? { borderRightWidth: thiccness }
      : { borderBottomWidth: thiccness })),
}));

function HeroSection() {
  const theme = useTheme();
  return (
    <Stack
      pb={5}
      sx={{ maxWidth: theme.breakpoints.values.xl, bgcolor: theme.palette.primary.lightest }}
    >
      <Navbar staticNavbar />
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        px={5}
        mt="120px"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Box>
            <Avatar src={DOCTOR_IMAGE} sx={{ width: {md:'142px',xs:'100px'}, height: {md:'142px',xs:'100px'} }} />
          </Box>
          <Stack ml={2} justifyContent='space-between'>
            <Typography variant="h4" fontSize={{md:'28px',xs:'18px'}} color="initial">
              Dr. Joshua Berkowitz, MD
            </Typography>
            <Typography variant="body1" color="grey.800">
              Gastroenterologist
            </Typography>
            <Stack direction="row" flexWrap='wrap' >
              <Typography variant="body1" mr={1} color="grey.900">
                Manhasset, NY
              </Typography>
              <Typography variant="body1" color="grey.700">
                • 3 locations
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack flexWrap="wrap" gap={3} direction="row"  mt={5}>
          <Stack >
            <Stack direction="row" alignItems="center" gap={1}>
              <Box>
                <Star size={24} weight="fill" className="ph-fill" color="red" />
              </Box>
              <Typography variant="body1" color="initial">
                Overall rating
              </Typography>
            </Stack>
            <Typography variant="h3">4.93</Typography>
            <a>
              <Typography variant="body1" sx={{ color: 'info.main', textDecoration: 'underline' }}>
                103 verified users
              </Typography>
            </a>
          </Stack>
         
          <MyDivider orientation="vertical"  thiccness={2} flexItem />
          <Stack >
            <Stack direction="row" alignItems="center" gap={1}>
              <Box>
                <ThumbsUp size={24} weight="fill" className="ph-fill" color="red" />
              </Box>
              <Typography variant="body1" color="initial">
                Highy recommended
              </Typography>
            </Stack>
            <Typography variant="body1" fontWeight="400" width="200px" color="grey.700">
              96% of patients gave this doctor 5 stars
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default HeroSection;
