import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

function CTASection() {
  const theme = useTheme();

  return (
    <Box px={{md:12,xs:4}} py={8} mt={8} bgcolor='primary.lighter'>
        <Typography mb={{md:5,xs:2}} fontWeight={500} textAlign='center' variant="h2">
            Lets get you a doc who gets you 
        </Typography>
        <Stack gap={{md:5,xs:2}} mt={{md:15,xs:6}} direction={{md:'row',xs:'column'}}>
            <Stack px={2} py={2} flex={1} justifyContent='space-between' sx={{bgcolor:theme.palette.background.paper,borderRadius:1}} >
            <Box height={{md:'auto',xs:'200px'}} marginX='auto' >
                    <img src="/assets/consult-image.jpg" style={{objectFit:'cover'}} height='100%' width={300} alt="" />
                </Box>
                <Stack>
                    <Typography variant="h4" mb={2} textAlign='center' fontWeight={500} mt={2} >
                        Browse Providers who take your insurance
                    </Typography>
                    <Button variant='outlined' >
                        See specialties
                    </Button>
                </Stack>
            </Stack>
            <Stack px={2} py={2} flex={1} justifyContent='space-between' sx={{bgcolor:theme.palette.background.paper,borderRadius:1}} >
                <Box height={{md:'auto',xs:'200px'}} marginX='auto' >
                    <img src="/assets/consult-image.jpg" style={{objectFit:'cover'}} height='100%' width={300} alt="" />
                </Box>
                <Stack>
                    <Typography variant="h4" mb={2} textAlign='center' fontWeight={500} mt={2} >
                        Read Reviews From users
                    </Typography>
                    <Button variant='outlined' >
                        See Providers
                    </Button>
                </Stack>
            </Stack>
            <Stack px={2} py={2} flex={1} justifyContent='space-between' sx={{bgcolor:theme.palette.background.paper,borderRadius:1}} >
            <Box height={{md:'auto',xs:'200px'}} marginX='auto' >
                    <img src="/assets/consult-image.jpg" style={{objectFit:'cover'}} height='100%' width={300} alt="" />
                </Box>
                <Stack>
                    <Typography variant="h4" mb={2} textAlign='center' fontWeight={500} mt={2} >
                        Book an Appointment today, online
                    </Typography>
                    <Button variant='outlined' >
                        See Availability
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    </Box>
  )
}

export default CTASection