import { Box,Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';


const specialtyOptions = [
  {
    value: 'internal-medicine',
    label: 'Internal Medicine',
    image: '/assets/baby.png',
  },
  {
    value: 'psychiatry',
    label: 'Psychiatry',
    image: '/assets/baby.png',
  },
  {
    value: 'urology',
    label: 'Urology',
    image: '/assets/baby.png',
  },
  {
    value: 'dermatology',
    label: 'Dermatology',
    image: '/assets/baby.png',
  },
  {
    value: 'infectious-diseases',
    label: 'Infectious Diseases',
    image: '/assets/baby.png',
  },
];

function SpecialistSection() {
  const theme = useTheme();
  return (
    <Box px={4} py={{md:12,xs:8}} sx={{ maxWidthidth: theme.breakpoints.values.xl }}>
      <Typography variant="h3" textAlign='center' mb={2} width={{md:'45%'}} mx='auto'>Consult Top Doctors Online For Any Health Cocern</Typography>
      <Typography variant="h6" fontWeight={500} textAlign='center' color="grey.700">
        Private online consultations with verified doctors in all spealists
      </Typography>
      <Stack direction='row' flexWrap='wrap' mt={5} justifyContent='space-between' gap={{md:8,xs:2}}>
      {specialtyOptions.map((specialty) => (
          <SpealistCard
            key={specialty.value}
            imageSrc={specialty.image}
            specialistTitle={specialty.label}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default SpecialistSection;

function SpealistCard({ imageSrc, specialistTitle }) {
  const theme = useTheme();

  return (
    <Box
      sx={{ minWidth:'200px',bgcolor: theme.palette.grey[200], cursor: 'pointer', borderRadius: 1 }}
      px={2}
      py={2}
      flex={1}
    >
      <Stack mb={1} alignItems='center' mx='auto'>
        <img src={imageSrc}  alt="specialist" style={{ width: '50px', height: 'auto' }} />
      </Stack>
      <Typography mb={0.5} textAlign='center' fontWeight={500} fontsize='18px' color="initial">
        {specialistTitle}
      </Typography>
    </Box>
  );
}


// Define prop types
SpealistCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  specialistTitle: PropTypes.string.isRequired,
};
