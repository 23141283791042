import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import PropTypes from 'prop-types';
import { Link, NavLink} from 'react-router-dom';
import {  Bell, SignOut, List as ListIcon } from 'phosphor-react';
import Logo from '../../components/logo/Logo';
import { useAuthManager } from '../../feature/auth-manager';

const defaultNavigationLinks = [
  { path: '/patient/home', title: 'Home' },
  { path: '/patient/appointment', title: 'Appointments' },
];

function Navbar({ navigationLinks = defaultNavigationLinks }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { logout } = useAuthManager();

  return (
    <Box
      sx={{ width: 1, bgcolor: theme.palette.background.paper, boxShadow: theme.shadows[1] }}
      px={4}
    >
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Stack justifyContent="space-between" height={1}>
          <List sx={{ width: '80vw', maxWidth: '500px', paddingTop: 5 }}>
            {navigationLinks.map((link, index) => (
              <ListItem key={link.title} disablePadding>
                <ListItemButton>
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box paddingBottom={2}>
            <ListItemButton>
              <Avatar />{' '}
              <Stack marginLeft={2}>
                <Typography variant="body1" color="initial">
                  John Doe
                </Typography>
                <Typography variant="subtitle1" color="initial">
                  Johndoe@gmail.com
                </Typography>
              </Stack>
              <IconButton sx={{ marginLeft: 'auto' }}>
                <SignOut />
              </IconButton>
            </ListItemButton>
          </Box>
        </Stack>
      </Drawer>
      <Stack
        marginX="auto"
        sx={{ height: '72px', maxWidth: theme.breakpoints.values.xl }}
        direction="row"
        alignItems="center"
      >
        <Box sx={{ marginRight: '110px' }}>
          <Logo />
        </Box>
        <Stack direction="row" sx={{ display: { xs: 'none', md: 'flex' } }} spacing={0.5}>
          {navigationLinks.map((link, index) => (
            <NavItem key={index} path={link.path} title={link.title} />
          ))}
        </Stack>
        <Stack
          direction="row"
          sx={{ display: { xs: 'none', md: 'flex' } }}
          marginLeft="auto"
          alignItems="center"
        >
          <IconButton>
            <Bell size={20} />
          </IconButton>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <Avatar
                  {...bindTrigger(popupState)}
                  sx={{ marginLeft: 2 }}
                  src="/assets/profile-picture.webp"
                />

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Stack p={2} gap={1}>
                    <Link to="/user/profile">
                      <Button variant="outlined" color="primary">
                        Profile
                      </Button>
                    </Link>
                    <Button onClick={()=>{logout()}} variant="contained" color="primary">
                      Sign out
                    </Button>
                  </Stack>
                </Popover>
              </div>
            )}
          </PopupState>
        </Stack>
        <Button sx={{ display: { md: 'none' }, marginLeft: 'auto' }} onClick={() => setOpen(true)}>
          <ListIcon size={36} />
        </Button>
      </Stack>
    </Box>
  );
}

Navbar.propTypes = {
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

function NavItem({ path, title }) {
  return (
    <NavLink className="default-link" to={path}>
      <Typography
        variant="body1"
        color="textPrimary"
        py={2}
        px={2.5}
        sx={{ textDecoration: 'none', fontWeight: 600 }}
      >
        {title}
      </Typography>
    </NavLink>
  );
}

export default Navbar;

// Add PropTypes for prop validation
NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
