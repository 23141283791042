import React from 'react';
import { Stack, Grid, Box, styled, Typography, useTheme } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CurrencyDollar } from 'phosphor-react';

export function SpendingSection() {
  const theme = useTheme();

  const BorderLinearProgress = styled(LinearProgress)(({ theme: localTheme }) => ({
    height: 10,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: localTheme.palette.grey[localTheme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h5">
        How much did you spend ?
      </Typography>
      <Stack
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Stack pt={2} px={2}>
          <Typography variant="h5">
            {`Amount you've spent on 12 new Patient bookings from askdoc`}{' '}
          </Typography>
          <br />
          <Stack px={2} direction="row" gap={2} py={2} bgcolor={theme.palette.background.neutral}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              borderRadius={50}
              px={2}
              bgcolor={theme.palette.primary.main}
            >
              <CurrencyDollar color="#fff" size={24} weight="fill" />
            </Stack>
            <Box>
              <Typography sx={{ mb: 1 }} variant="h5">
                $650/$800
              </Typography>
              <BorderLinearProgress variant="determinate" value={50} />
            </Box>
          </Stack>
        </Stack>
        <Typography sx={{ px: 4, py: 2 }}>
          <a href="#">Scheduled a call </a> &nbsp; &nbsp; &nbsp;
          <a href="#">Edit budget</a> 
        </Typography>
      </Stack>
    </>
  );
}
