import { create } from 'zustand';
import { ROLE } from '../config-global';


const useAuthStore = create((set) => ({
    isLoggedIn: false,
    user: { name: '', role: ROLE.NoUser, },
    setUser: (user) => {
        set((state) => ({
            ...state,
            user,
            isLoggedIn: true,
        }));
    },
}));


export default useAuthStore;

