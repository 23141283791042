import { Grid, useTheme } from '@mui/material';
import { TotalBookingSection } from './total-booking';
import { ValueSection } from './value-section';
import { ReasonSection } from './reason-section';
import { ProfileSection } from './profile-section';
import { SpendingSection } from './spending-section';
import { ReviewSection } from './review-section';
import { AppointmentSection } from './appointment-section';
import { BookingSection } from './booking-section';
/* import { AppointmentSection } from './appointment-section';
 */
function PerformanceSection() {
  const theme = useTheme();
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={7}>
        <TotalBookingSection />
      </Grid>
      <Grid item xs={12} md={5}>
        <ValueSection />
      </Grid>
      <Grid item xs={12} md={7}>
        <ReasonSection />
      </Grid>
      <Grid item xs={12} md={5}>
        <ProfileSection />
      </Grid>
      <Grid item xs={12} md={5}>
        <SpendingSection />
      </Grid>
      <Grid item xs={12} md={7}>
        <ReviewSection />
      </Grid>
      <Grid item xs={12} md={7}>
        <AppointmentSection />
      </Grid>
      <Grid item xs={12} md={5}>
        <BookingSection />
      </Grid>
    </Grid>
  );
}

export default PerformanceSection;
