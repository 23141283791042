import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import useAuthStore from '../store/auth-store';
// components
import Logo from '../components/logo/Logo';
import { useAuthManager } from '../feature/auth-manager';

// ----------------------------------------------------------------------

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuthStore();
  const { loginFromCookie } = useAuthManager();

  useEffect(() => {
    if (!isLoggedIn) {
      const loginUser = async () => {
        await loginFromCookie();
      };
      loginUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn)
    return (
      <Stack gap={2} height="100vh" width="100vw" justifyContent="center" alignItems="center">
        <Logo />
        <Typography variant="h4">Please wait...</Typography>
      </Stack>
    );

    console.log(isLoggedIn,"Logged in");

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
export default AuthGuard;
