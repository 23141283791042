import React from 'react';
import { Stack, Grid, styled, Typography, useTheme } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { MyDivider } from '../doctor/doctor-details/Hero-section';

const categories = [
  { name: 'Illness', progress: 50 },
  { name: 'Annual Checkup', progress: 75 },
  { name: 'Prescription/Refill', progress: 25 },
  { name: 'Annual Pop Smear', progress: 65 },
  { name: 'Others', progress: 25 },
  // Add more categories as needed
];
export function ReasonSection() {
  const theme = useTheme();

  const BorderLinearProgress = styled(LinearProgress)(({ theme: localTheme }) => ({
    height: 10,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: localTheme.palette.grey[localTheme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <>
      <Typography sx={{ mb: 1 }} variant="h5">
        What was your patient mix?
      </Typography>
      <Stack
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Stack py={4} px={2}>
          <Typography variant="h5">Visit Reason</Typography>
          <br />
          {categories.map((category, index) => (
            <Grid container rowSpacing={2} mb={1} alignContent="center" key={index}>
              <Grid item xs={12} md={5}>
                <Stack alignItems="center" direction="row">
                  <Typography variant="body">{category.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={7}>
                <BorderLinearProgress variant="determinate" value={category.progress} />
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Stack>
    </>
  );
}
