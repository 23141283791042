import React from 'react';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import { Cursor, Eye } from 'phosphor-react';

export function ProfileSection() {
  const theme = useTheme();

  return (
    <Stack height="100%">
      <Typography sx={{ mb: 1 }} variant="h5">
        What was your patient mix?
      </Typography>
      <Stack
        flex={1}
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Stack flex={1}>
          <Stack flex={1} direction={{md:"row",xs:'column'}} justifyContent="center">
            <Stack justifyContent='center' flex={1} p={2} borderRight="2px solid" borderColor={theme.palette.grey[400]}>
              <Stack mb={1} direction="row" justifyContent="center">
                <Eye size={40} />
              </Stack>
              <Typography textAlign="center" variant="h3">
                1900
              </Typography>
              <Typography variant="h6" fontWeight={500} textAlign="center">
                Impressions
              </Typography>
              <Box
                marginX="auto"
                height="2px"
                bgcolor={theme.palette.primary.main}
                my={1}
                width="40%"
              />
              <Typography textAlign="center">
                Times your profile has shown in search results
              </Typography>
            </Stack>
            <Stack justifyContent='center' flex={1} p={2}>
              <Stack mb={1} direction="row" justifyContent="center">
                <Cursor size={40} />
              </Stack>
              <Typography textAlign="center" variant="h3">
                800
              </Typography>
              <Typography variant="h6" fontWeight={500} textAlign="center">
                Clicks
              </Typography>
              <Box
                marginX="auto"
                height="2px"
                bgcolor={theme.palette.primary.main}
                my={1}
                width="40%"
              />
              <Typography textAlign="center">Times Patient has clicked is your profile</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
