import { Stack, Box, Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { MyDivider } from '../doctor/doctor-details/Hero-section';

export function ValueSection() {
  const theme = useTheme();

  return (
    <Stack
      bgcolor={theme.palette.background.paper}
      height="100%" 
      justifyContent='space-between'
      boxShadow={theme.shadows[5]}
      borderRadius={1}
    >
      <Box
        borderTop="4px solid"
        py={4}
        bgcolor={theme.palette.background.neutral}
        px={2}
        borderColor={theme.palette.primary.main}
        borderRadius={1}
      >
        <Typography variant="h5">Value for new Patients</Typography>
        <Typography variant="h2" color={theme.palette.success.main}>
          $3000
        </Typography>
      </Box>
      <Stack py={4} px={2}>
        <Grid container rowSpacing={2} alignContent="center">
          <Grid item xs={9}>
            Average you recieve per new Patient in a year
          </Grid>
          <Grid item xs={3} >
            <Stack direction='row' gap={1} justifyContent="end" alignItems="end">
              <Link>update</Link>
              <Typography width="fit-content" textAlign="left" color={theme.palette.success.main}>
                $275
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            new Patient from Askdoc
          </Grid>
          <Grid item xs={3}>
            <Stack justifyContent="end" alignItems="end">
              <Typography width="fit-content" textAlign="left">
                75
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <MyDivider />
      <Typography sx={{px:4,py:2}}>
        <a href="#">Scheduled a call </a> for a free custom analysis
      </Typography>
    </Stack>
  );
}
