import {
  Box,
  Stack,
  useTheme,
  Typography,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import React from 'react';
import { MapPin, MagnifyingGlass } from 'phosphor-react';
import PropTypes from 'prop-types';

const options = [
  { title: 'Doctor', description: 'Book an appointment' },
  { title: 'Consult', description: 'with top doctors' },
  { title: 'Pahrmacy', description: 'Medicine Products' },
  { title: 'Diagonist', description: 'Tests and checkup' },
];
function HeroSection() {
  const theme = useTheme();
  return (
    <Stack justifyContent="center" pb={4} pt={15} position="relative" alignItems="center">
      <Box bgcolor={theme.palette.primary.lightest} height={{md:"calc(100% - 350px)",xs:"calc(100% - 150px)"}} width={1} zIndex={-1} top={0} position="absolute" />
      <Box sx={{ maxWidthidth: theme.breakpoints.values.xl }}>
        <Typography variant="h2" mb={3} textAlign="center" color="primary">
          Modern, Smart, Hassle-free online medical care
        </Typography>
        <Typography variant="h6" fontWeight={500} textAlign="center" color="grey.700">
          24/7 Private video and text consultations. Starts at just $10.
        </Typography>
        <Stack
          direction={{md:"row",xs:'column'}}
          sx={{ bgcolor: theme.palette.background.paper }}
          my={6}
          mx={4}
          px={2}
          py={1}
          borderRadius={{md:50,xs:2}}
        >
          <Autocomplete
            disablePortal
            size="small"
            fullWidth
            id="combo-box-demo"
            options={['option1', 'option2']}
            renderInput={(params) => (
              <TextField
                placeholder="Search Doctor"
                size="small"
                {...params}
                /*  InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ marginLeft: 1 }} position="start">
                    <IconButton edge="start">
                      <MagnifyingGlass />
                    </IconButton>
                  </InputAdornment>
                ),
                ...params.inputProps,
              }} */
                /*               {...params}
                 */
              />
            )}
          />
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={['option1', 'option2']}
            sx={{ width: {md:200}, outline: 'none', border: 'none !important' }}
            renderInput={(params) => (
              <TextField
                placeholder="50003"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment sx={{ marginLeft: 1 }} position="start">
                      <IconButton edge="start">
                        <MapPin />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...params}
                sx={{ border: 'none !important' }}
                /*               {...params}
                 */
              />
            )}
          />
          <Button variant="contained">
            <Stack direction="row" gap={1} px={2}>
              <MagnifyingGlass size={20} />
              Search
            </Stack>
          </Button>
        </Stack>
        <Box px={2}>
          <Typography variant="h6" mb={2} color="">
            Are you looking For:
          </Typography>
          <Stack direction={{md:"row",xs:'column'}}   gap={2}>
            {options.map((option) => (
              <OptionCard title={option.title} description={option.description}/>
            ))}
          </Stack>
        </Box>
      </Box>
      <Box width="70%">
        <img
          width="100%"
          src="https://kivicare.io/wp-content/uploads/2022/06/google_meet.png"
          alt=""
        />
      </Box>
    </Stack>
  );
}

export default HeroSection;

function OptionCard({title,description}) {
  const theme = useTheme();
  return (
    <Box
     flex={1}
      sx={{
        ':hover': { bgcolor: theme.palette.primary.lighter },
        cursor: 'pointer',
        bgcolor: theme.palette.background.paper,
        borderRadius: 1,
      }}
      px={2}
      py={2}
    >
      <Typography mb={0.5} variant="h5" color="initial">
        {title}
      </Typography>
      <Typography variant="body1" color="inherit">
        {description}
      </Typography>
    </Box>
  );
}



OptionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};