import { Box, Stack, Typography,Tab, useTheme } from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import PerformanceSection from '../../sections/doctor-dashboard/perfomance';

function DoctorLayout() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  return (
    <Stack px={{ md: 12, xs: 2 }} minHeight='100vh' py={5}  spacing={2}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Performace" value="1" />
            <Tab label="Appointment reports" value="2" />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <PerformanceSection />
        </TabPanel>
      </TabContext>
    </Stack>
  );
}

DoctorLayout.propTypes = {};

export default DoctorLayout;
