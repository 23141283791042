import { Box, Stack, useTheme,Typography, Button } from '@mui/material';
import React from 'react';

function CTASecondSection() {
  const theme = useTheme();
  return (
    <Box>
      <Stack direction={{md:"row",xs:"column"}} mx={{md:12,xs:4}}  gap={{md:8,xs:4}} py={10} sx={{ maxWidth: theme.breakpoints.values.xl }}>
        <Box flex={1}>
          <img
            src="/assets/doctor-laptop.jpg"
            style={{height:'300px',width:'100%',objectFit:'cover', marginLeft: 'auto' }}
            alt="feature"
          />
        </Box>
        <Box flex={1}>
          <Stack gap={1}>
            <Typography  variant="h6" color='grey.600' textTransform='uppercase'>
                Askdoc for private practices
            </Typography>
            <Typography fontWeight={500} variant="h3">
                Are you a provider interested in reaching new Patients ?
            </Typography>
            <Stack ml={4} mb={2}>
                <Typography sx={{display:'list-item',listStyleType:'disc'}} variant="h6" fontFamily='400' color="grey.700">
                    Reach patients in your area looking for a new provider
                </Typography>
                <Typography sx={{display:'list-item',listStyleType:'disc'}} variant="h6" fontFamily='400' color="grey.700">
                    Fill last-minute openings in your schedule
                </Typography>
                <Typography sx={{display:'list-item',listStyleType:'disc'}} variant="h6" fontFamily='400' color="grey.700">
                    Strengthen your online reputation with verified reviews
                </Typography>
            </Stack>
            <Box>
            <Button variant='contained'>List your practice on askdoc</Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default CTASecondSection;
