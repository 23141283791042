import { Box, Stack, useTheme, Avatar, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyDollar, Paperclip, TrendUp } from 'phosphor-react';

const features = [
  {
    title: 'Always Available',
    description: 'Skip a trip and start visit anytime with any device',
    Icon: TrendUp,
  },
  {
    title: `A  price that doesn't hurt`,
    description: 'Your visit is $10 and even less',
    Icon: CurrencyDollar,
  },
  {
    title: `Priscription and Treatment plans`,
    description: 'You will get a customised plan with us from your favorite pharmacy',
    Icon: Paperclip,
  },
];
function FeaturesSection() {
  const theme = useTheme();
  return (
    <Box>
      <Stack px={4} direction={{md:"row",xs:'column'}} gap={5} sx={{ maxWidth: theme.breakpoints.values.xl }}>
        <Box flex={1}>
          <img
            src="/assets/feature-image.png"
            style={{ width: '80%', marginLeft: 'auto' }}
            alt="feature"
          />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle1" color="grey.700">
            Why simple is better
          </Typography>
          <Typography mb={3} variant="h3" color="initial">
            Smart and Affordable
          </Typography>
          <Stack gap={4}>
            {features.map((feature) => (
              <FeatureCard {...feature} />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default FeaturesSection;
function FeatureCard(props) {
  const theme = useTheme();
  const { title, description, Icon } = props;

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack
          alignItems="center"
          justifyContent='center'
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: 10,
            bgcolor: 'primary.lighter',
          }}
        >
          <Icon color={theme.palette.grey[800]} size={20} />
        </Stack>
        <Stack>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <Typography variant="body1" color="grey.700">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
};
