import React from 'react';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import { Cursor, Eye } from 'phosphor-react';
import Chart from '../../components/chart';

// You can now use these options with your charting library (e.g., Highcharts, ApexCharts, etc.)
const chart = {
  series: [
    {
      data: [21, 22, 10, 28, 16, 21,],
    },
  ],
  options: {
    chart: {
      height: 250,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
      ],
    },
  },
};
export function AppointmentSection() {
  const theme = useTheme();

  return (
    <Stack height="100%">
      <Typography sx={{ mb: 1 }} variant="h5">
        When were Your booking made?
      </Typography>
      <Stack
        flex={1}
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Chart type="bar" series={chart.series} height={350} options={chart.options} />
      </Stack>
    </Stack>
  );
}
