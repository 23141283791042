import { Outlet } from 'react-router';
// @mui
import { useTheme, Box } from '@mui/material';
import Navbar from './navbar';
import Footer from './footer';

// ----------------------------------------------------------------------



export default function PatientLayout() {
  const theme = useTheme();
  return (
    <Box sx={{ bgcolor: theme.palette.background.neutral,maxWidth:theme.breakpoints.values.xl, minHeight: '100vh', position: 'relative' }}>
      <Navbar />
      <Outlet/>
      <Footer/>
    </Box>
  );
}
