import api from './config/axios-config';

export const authAPI = {
  registerPatient: async (data) => api.post('/auth/patient/register', data),
  loginPatient: async (data) => api.post('/auth/patient/login', data),

  loginAdmin: async (data) => api.post('/auth/admin/login', data),

  registerDoctor: async (data) => api.post('/auth/doctor/register', data),
  loginDoctor: async (data) => api.post('/auth/doctor/login', data),

  setupStep1Doctor: async (data) => api.post('/auth/doctor/setup/step-1', data),
  setupStep2Doctor: async (data) => api.post('/auth/doctor/setup/step-2', data),
  setupStep3Doctor: async (data) => api.post('/auth/doctor/setup/step-3', data),
  setupStep4Doctor: async (data) => api.post('/auth/doctor/setup/step-4', data),

  verifyEmail: async (data) => api.post('/auth/patient/verify-email', data),
  getUser: async () => api.get('/auth/user'),
  logout: async () => api.get('/auth/logout'),
};
