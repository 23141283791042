import React from 'react';
import { Stack, Box, Typography, useTheme, Avatar, Rating } from '@mui/material';
import { Cursor, Eye } from 'phosphor-react';

const reviews = [
  {
    id: 1,
    avatar: 'https://www.felixhospital.com/sites/default/files/2022-11/dr-dk-gupta.jpg',
    name: 'Dr. Dk Gupta',
    rating: 4,
    reviewCount: 20,
  },
  {
    id: 2,
    avatar: 'https://www.felixhospital.com/sites/default/files/2022-11/dr-dk-gupta.jpg',
    name: 'Dr. John Smith',
    rating: 5,
    reviewCount: 30,
  },
  {
    id: 3,
    avatar: 'https://www.felixhospital.com/sites/default/files/2022-11/dr-dk-gupta.jpg',
    name: 'Dr. Sarah Johnson',
    rating: 3,
    reviewCount: 15,
  },
];

export function ReviewSection() {
  const theme = useTheme();

  return (
    <Stack height="100%">
      <Typography sx={{ mb: 1 }} variant="h5">
        What do patients think?{' '}
      </Typography>
      <Stack
        flex={1}
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Stack flex={1} direction={{ md: 'row', xs: 'column' }}>
          {reviews.map((review) => (
            <Stack key={review.id} flex={1} direction="row" justifyContent="center">
              <Stack
                justifyContent="center"
                flex={1}
                p={2}
                borderRight="2px solid"
                borderBottom={{ xs: `2px solid ${theme.palette.grey[200]}`, md: '0' }}
                borderColor={theme.palette.grey[200]}
              >
                <Stack mb={1} direction="row" justifyContent="center">
                  <Avatar src={review.avatar} />
                </Stack>
                <Typography variant="h6" fontWeight={500} textAlign="center">
                  {review.name}
                </Typography>
                <Box
                  marginX="auto"
                  height="2px"
                  bgcolor={theme.palette.grey[300]}
                  my={1}
                  width="40%"
                />
                <Stack gap={1} width={1} alignItems="center">
                  <Rating value={review.rating} readOnly />
                  <a href="#">{review.reviewCount} Reviews</a>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
