import React from 'react';
import { Stack, Typography, useTheme, Avatar } from '@mui/material';

const bookings = [
  {
    id: 1,
    name: 'Taylor',
    date: '12/29',
    reason: 'illness',
  },
  {
    id: 2,
    name: 'Emma',
    date: '12/30',
    reason: 'check-up',
  },
  {
    id: 3,
    name: 'James',
    date: '12/31',
    reason: 'surgery',
  },
  {
    id: 4,
    name: 'Olivia',
    date: '01/01',
    reason: 'consultation',
  },
];

export function BookingSection() {
  const theme = useTheme();

  return (
    <Stack height="100%">
      <Typography sx={{ mb: 1 }} variant="h5">
        Who were you booking?
      </Typography>
      <Stack
        flex={1}
        bgcolor={theme.palette.background.paper}
        justifyContent="space-between"
        boxShadow={theme.shadows[5]}
        borderRadius={1}
      >
        <Stack pt={2} px={2}>
          {bookings.map((booking) => (
            <Stack
              key={booking.id}
              px={2}
              direction="row"
              alignItems="center"
              borderBottom="1px solid"
              borderColor={theme.palette.grey[300]}
              gap={2}
              py={2}
            >
              <Avatar />
              <Typography>
                {booking.name} booked on <bold>{booking.date} </bold> for {booking.reason}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Typography sx={{ px: 4, py: 2 }}>
          <a href="#">View Booking Details</a>
        </Typography>
      </Stack>
    </Stack>
  );
}
