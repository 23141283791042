import { Stack, Box, Typography, useTheme } from '@mui/material';
import Chart, { useChart } from '../../components/chart';

export function TotalBookingSection() {
  const theme = useTheme();
  const series = [44, 55, 13];

  const chartOptions = useChart({
    labels: [
      'New Patient Booking from askdoc',
      'Existing Patient Booking from patient',
      'Booking From your Site',
    ],
    stroke: {
      show: false,
    },
    legend: {
      position: 'left',
      height: '600',
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
          value: {
            show: false,
          },
          size: '80%',
        },
        dataLabels: {
          position: 'left',
        },
      },
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        options: {
          legend: {
            horizontalAlign: 'center',
            position: 'bottom',
            height: '100',
            itemMargin: {
              horizontal: 10,
              vertical: 1,
            },
            itemStyle: {
              width: 100,
            },
          },
          chart: {
            width: '500px',
          },
        },
      },
      {
        breakpoint:theme.breakpoints.values.sm,
        options:{
          chart: {
            width: '350px',
          }
        }
      }
    ],
  });
  return (
    <Stack bgcolor={theme.palette.background.paper} boxShadow={theme.shadows[5]} borderRadius={1}>
      <Box
        borderTop="4px solid"
        py={4}
        bgcolor={theme.palette.background.neutral}
        px={2}
        borderColor={theme.palette.primary.main}
        borderRadius={1}
      >
        <Typography variant="h4">Total Booking</Typography>
        <Typography variant="h2">18</Typography>
      </Box>
      <Stack direction="row" justifyContent="center">
        <Chart type="donut" series={series} options={chartOptions} width={600} />
      </Stack>
    </Stack>
  );
}
