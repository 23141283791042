import { Navigate, useRoutes } from 'react-router-dom';
import LandingPage from '../pages/landing-page';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import PatientLayout from '../layouts/patient/PatientLayout';
import UserMenuLayout from '../layouts/user-menu-layout';

// config
import { PATH_AFTER_LOGIN, ROLE } from '../config-global';
//
import {
  DoctorSetupFormPage,
  Page404,
  JoinAsDoctorPage,
  DoctorSignUpPage,
  PageOne,
  PageTwo,
  ThankYouPage,
  DoctorProfile,
  PageSix,
  PageFour,
  OnBoardingInfoPage,
  PageFive,
  LoginPage,
  PageThree,
  SearchDoctorPage,
  SignupPage,
  ForgotPasswordPage,
  CheckEmailPage,
  NewPasswordPage,
  PasswordResetPage,
  EmailVerifiedPage,
  VerifyEmailPage,
  ChatPage,
  HomePage,
  AppointmentsPage,
  DoctorDetails,
  BookAppointment,
  BookAppointmentConfirm,
  PatientInfoPage,
  AppointmentFeedbackSection,
  UpcomingAppointment,
  PastAppointment,
  ProfileSection,
  LoginAndSecurityPage,
  DoctorsListPage,
  SubscribtionCenter,
  PermissionPage,
  PrivacySettingsPage,
  VideoPage,
} from './elements';

import {
  PATH_ABOUT,
  PATH_ADMIN,
  PATH_APPOINTMENT,
  PATH_DOCTOR,
  PATH_DOCTOR_DASHBOARD,
  PATH_GROW,
  PATH_MEETING,
  PATH_PATIENT,
  PATH_USERMENU,
} from './paths';
import AdminLayout from '../layouts/admin/adminLayout';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import DoctorLayout from '../layouts/doctor-dashboard/doctor-dashboard-layout';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <LandingPage />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignupPage />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'check-email',
          element: (
            <GuestGuard>
              <CheckEmailPage />
            </GuestGuard>
          ),
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'password-reset',
          element: (
            <GuestGuard>
              <PasswordResetPage />
            </GuestGuard>
          ),
        },
        {
          path: 'email-verified',
          element: (
            <GuestGuard>
              <EmailVerifiedPage />
            </GuestGuard>
          ),
        },
        {
          path: 'verify-email',
          element: (
            <GuestGuard>
              <VerifyEmailPage />
            </GuestGuard>
          ),
        },
        {
          path: 'search',
          element: (
            <GuestGuard>
              <SearchDoctorPage />
            </GuestGuard>
          ),
        },
        {
          path: 'chat',
          element: (
            <GuestGuard>
              <ChatPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'one', element: <PageOne /> },
        { path: 'two', element: <PageTwo /> },
        { path: 'three', element: <PageThree /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },
    {
      path: PATH_PATIENT.root,

      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent role={ROLE.Patient}>
            <PatientLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { index: true, element: <HomePage /> },
        { path: PATH_PATIENT.home, index: true, element: <HomePage /> },
        { path: PATH_PATIENT.appointments, element: <AppointmentsPage /> },
      ],
    },
    {
      path: PATH_DOCTOR.root,
      children: [{ path: PATH_DOCTOR.home, element: <DoctorDetails /> }],
    },
    {
      path: PATH_APPOINTMENT.root,
      children: [
        { index: true, element: <BookAppointment /> },
        { path: PATH_APPOINTMENT.book, element: <BookAppointment /> },
        { path: PATH_APPOINTMENT.confirm, element: <BookAppointmentConfirm /> },
        { path: PATH_APPOINTMENT.patientInfo, element: <PatientInfoPage /> },
        { path: PATH_APPOINTMENT.feedback, element: <AppointmentFeedbackSection /> },
      ],
    },
    {
      path: PATH_PATIENT.root,
      element: <PatientLayout />,
      children: [
        { path: PATH_PATIENT.home, index: true, element: <HomePage /> },
        { path: PATH_PATIENT.upcomingAppointment, element: <UpcomingAppointment /> },
        { path: PATH_PATIENT.pastAppointment, element: <PastAppointment /> },
        { path: PATH_PATIENT.appointments, element: <AppointmentsPage /> },
      ],
    },
    {
      path: PATH_ADMIN.root,

      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent role={ROLE.Admin}>
            <AdminLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_ADMIN.doctors} /> },
        { path: PATH_ADMIN.doctors, element: <DoctorsListPage /> },
        { path: PATH_ADMIN.doctorProfile, element: <DoctorProfile /> },
      ],
    },
    {
      path: PATH_DOCTOR_DASHBOARD.root,

      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent role={ROLE.Doctor}>
            <DoctorLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
    },
    {
      path: PATH_MEETING.root,
      element: <VideoPage />,
    },
    {
      path: PATH_ABOUT.root,
      children: [
        {
          index: true,
          element: <Navigate to={PATH_ABOUT.join} />,
        },
        {
          path: PATH_ABOUT.join,
          element: <JoinAsDoctorPage />,
        },
      ],
    },
    {
      path: PATH_GROW.root,
      children: [
        {
          index: true,
          element: <Navigate to={PATH_GROW.signup} />,
        },
        {
          path: PATH_GROW.signup,
          element: <DoctorSignUpPage />,
        },
        {
          path: PATH_GROW.onboarding,
          element: <OnBoardingInfoPage />,
        },
        {
          path: PATH_GROW.setup,
          element: <DoctorSetupFormPage />,
        },
        {
          path: PATH_GROW.thankyou,
          element: <ThankYouPage />,
        },
      ],
    },
    {
      path: PATH_USERMENU.root,
      element: <UserMenuLayout />,
      children: [
        { path: PATH_USERMENU.profile, index: true, element: <ProfileSection /> },
        { path: PATH_USERMENU.password, element: <LoginAndSecurityPage /> },
        { path: PATH_USERMENU.subscriptionCenter, element: <SubscribtionCenter /> },
        { path: PATH_USERMENU.permissionManagment, element: <PermissionPage /> },
        { path: PATH_USERMENU.privacy, element: <PrivacySettingsPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
