import { useNavigate } from 'react-router';
import { authAPI } from '../apis/auth-API';
import useAuthStore from '../store/auth-store';
import { PATH_AUTH } from '../routes/paths';

export const useAuthManager = () => {
  const navigate = useNavigate();
  const { setUser } = useAuthStore();

  const loginFromCookie = async () => {
    try {
      const response = await authAPI.getUser();
      switch (response.status) {
        case 200:
          {
            const user = response.data.data;
            setUser(user);
          }
          break;
        default:
          navigate(PATH_AUTH.login);
          break;
      }
    } catch (err) {
      const response = err.response;
      if (!(err.response === undefined)) {
        switch (response.status) {
          case 404:
            navigate('/login');
            break;
          default:
            navigate('/login');
            break;
        }
      }
    }
  };

  const logout = async () => {
    try {
      const response = await authAPI.logout();
      setUser(null);
      navigate('/');
      localStorage.clear();
    } catch (err) {
      console.error(err);
      navigate('/');
    }
  };

  return { loginFromCookie, logout };
};
